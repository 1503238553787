/**
 * @name submitActivation - Submits an activation to the API
 * @param {object} activation - Activation object
 * @param {string} activation.instance_key - The instance key
 * @param {string} activation.email - The email
 * @param {string} activation.first_name - The first name
 * @param {string} activation.last_name - The last name
 * @param {boolean} activation.marketing_opt_in - The marketing opt in
 * @param {"en" | "fr"} activation.locale - The locale
 * @returns {Promise<string>} Returns a promise that resolves with the email
 */
export const submitActivation = async ({
  instance_key,
  email,
  first_name,
  last_name,
  marketing_opt_in,
  locale,
}) => {
  const formData = await $fetch("/api/form", {
    query: {
      instance_key,
    },
    method: "post",
    body: {
      email: email,
      answers: {
        "FIRST NAME": first_name,
        "LAST NAME": last_name,
        Q799E: "0799",
        A799E: marketing_opt_in ? "A" : "H",
        Q799M: "0799",
        A799M: "E",
        Q127: "0127",
        "A127 LANGUAGE": locale === "en" ? "C" : "B",
      },
    },
  });

  if (formData.status !== 200) {
    throw new Error(`Failed to create activation: ${formData.body.error}`);
  }

  return formData.body.email;
};

/**
 * @name submitSurvey - Submits a survey to the API
 * @param {string} instance_key - The instance key
 * @param {string} email - The email
 * @param {object} answers - The answers
 * @returns {Promise<string>} Returns a promise that resolves with the survey ID
 */
export const submitSurvey = async (
  instance_key,
  email,
  answers,
) => {
  const data = await $fetch("/api/form", {
    method: "post",
    query: {
      instance_key: instance_key,
    },
    body: {
      email: email,
      answers: {
        ...answers,
        Q2: "0002",
        COUNTRY: "CAN",
        Q75: "0075",
        "A75 MAKE": "FD",
        Q1439: "1439",
      },
    },
  });

  if (data.status !== 200) {
    throw new Error(`Failed to submit survey: (status ${data.status}) ${JSON.stringify(data)}`);
  }

  return data.body.id;
};
